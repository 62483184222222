import React from "react"
import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import BackButton from "@components/back_button"
import ForgotPasswordForm from "@components/_molecules/form_forgot_password"

const LoginPage = () => {
  const { password } = data
  return (
    <Layout type="light" pageClass="pledge">
      <BackButton data={password.backLink} />
      <SEO title={password.title} />
      <ForgotPasswordForm />
    </Layout>
  )
}

export default LoginPage
