import React, { Component } from "react"
import "@components/form_parent_registration/style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import HeaderandText from "@components/header_with_text"
import FormButton from "@components/_atoms/button_form_input_submit"
import InputLabelState from "@components/_molecules/input_label_state"
import * as data from "@data/pledge"
import { validate } from "@data/validations"

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      emailErrorText: "Invalid email",
      emailErrorStatus: false,
      buttonSubmitted: false,
      pwReqSuccess: false
    }
  }

  handleChange = ev => {
    this.setState({
      [ev.target.name]: ev.target.value,
      [`${ev.target.name}ErrorStatus`]: false
    })
  }

  handleSubmit = ev => {
    ev.preventDefault()
    let emailErrorStatus = !validate.email.test(this.state.email)
    this.setState({
      emailErrorStatus
    })
    if (!emailErrorStatus) {
      this.setState({ buttonSubmitted: true })

      let data = {
        email: this.state.email.toLowerCase()
      }

      const options = {
        method: "POST",
        data: JSON.stringify(data),
        headers: headers.getHeaders(),
        url: `${process.env.API_HOST}v1/users/generate_password_reset_token`
      }
      axios(options)
        .then(res => {
          this.setState({
            buttonSubmitted: false,
            pwReqSuccess: true,
            email: ""
          })
        })
        .catch(error => {
          console.log(error.response)
          this.setState({
            buttonSubmitted: false,
            generalError: error.response.data.errors[0].message
          })
        })
    }
  }

  render() {
    const { parentReg, password } = data
    return (
      <>
        <HeaderandText
          data={
            !this.state.pwReqSuccess
              ? password.heading
              : password.successHeading
          }
        />
        <div className="registration-form">
          <form onSubmit={this.handleSubmit}>
            <div
              style={{ display: this.state.pwReqSuccess ? `none` : `block` }}
            >
              <InputLabelState
                data={{
                  name: `Email`,
                  type: `email`,
                  helpText: this.state.emailErrorText,
                  errorStatus: this.state.emailErrorStatus,
                  defaultVisibility: 0,
                  value: this.state.email
                }}
                onChange={this.handleChange.bind(this)}
              />
            </div>
            <div style={{ width: `100%`, height: `3rem` }}></div>
            <FormButton
              text={
                this.state.pwReqSuccess ? `Check Your Email` : `Reset Password`
              }
              isDisabled={this.state.buttonSubmitted || this.state.pwReqSuccess}
            />
          </form>
        </div>
      </>
    )
  }
}

export default ForgotPasswordForm
